import { render, staticRenderFns } from "./LandingHome.vue?vue&type=template&id=5806c163&"
import script from "./LandingHome.vue?vue&type=script&lang=js&"
export * from "./LandingHome.vue?vue&type=script&lang=js&"
import style0 from "./LandingHome.vue?vue&type=style&index=0&id=5806c163&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports